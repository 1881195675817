<template>
  <div>
    <h1 class="title">Tenants in environment {{ $route.params["env"] }}</h1>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Fullpath</th>
            <th>Expiration date</th>
            <th>BlueGreen</th>
            <th>Cluster status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in envInfo" v-bind:key="index">
            <td>
              <router-link
                :to="{
                  name: 'Tenant',
                  params: { env: $route.params['env'], tenant: item.name },
                }"
                >{{ item.name }}</router-link
              >
            </td>
            <td>{{ item.fullpath }}</td>
            <td>
              <span
                class="tag is-success"
                v-bind:class="{
                  'is-danger':
                    item.expirationDate && item.expirationDate < new Date(),
                  'is-light': !item.expirationDate,
                }"
              >
                {{ formatDate(item.expirationDate) || "n/d" }}
              </span>
            </td>
            <td>
              <div class="tags has-addons" v-if="item.blueGreen">
                <span class="tag is-info">In</span>
                <span class="tag is-success"> Progress </span>
              </div>
            </td>
            <td>
              <span
                class="tag"
                v-bind:class="{
                  'is-black': item.status == 'unknown',
                  'is-danger': item.status == 'stopped',
                  'is-success': item.status == 'running'
                }"
              >
                {{ item.status }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import VersionsApi from "../app/get-versions-api";
import Utils from "../app/utils";
import axios from "axios";

export default {
  name: "Environment",
  data: function() {
    return {
      envInfo: [],
    };
  },
  methods:{
    formatDate: Utils.formatDate
  },
  mounted() {
    const reqEnvs=VersionsApi.getEnvironmentDetails(this.$route.params["env"]);
    const reqStatus=VersionsApi.getEnvironmentStatus(this.$route.params["env"]);
    axios
      .all([reqEnvs,reqStatus])
      .then(
        axios.spread((...responses) => {
              const resp = responses[0].data;
              const st = Utils.arrayToObject(responses[1].data, "name");
              // use/access the results
              console.log(responses);
              resp.forEach((el) => {
                el.status = st[el.name] ? (st[el.name].running?"running":"stopped") : "unknown";
                el.expirationDate =
                  el.expirationDate == "Not defined"
                    ? null
                    : new Date(el.expirationDate);
              });
              this.envInfo = resp;
            })
      );
  },
};
</script>
<style></style>
