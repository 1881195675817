require("@/assets/main.scss");
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronDown, faChevronUp);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

/*
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
*/
fetch(process.env.BASE_URL + "config.json?t=" + Math.round(new Date().getTime() / 1000))
  .then((response) => {
    response.json().then((config) => {
      window.$config = function (key) { return config[key] || process.env[key] };
      Vue.prototype.$config = window.$config;
      new Vue({
        render: (h) => h(App),
        router,
      }).$mount("#app");
    })
  });