var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Tenants in environment "+_vm._s(_vm.$route.params["env"]))]),_c('div',{staticClass:"row"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.envInfo),function(item,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'Tenant',
                params: { env: _vm.$route.params['env'], tenant: item.name },
              }}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_vm._v(_vm._s(item.fullpath))]),_c('td',[_c('span',{staticClass:"tag is-success",class:{
                'is-danger':
                  item.expirationDate && item.expirationDate < new Date(),
                'is-light': !item.expirationDate,
              }},[_vm._v(" "+_vm._s(_vm.formatDate(item.expirationDate) || "n/d")+" ")])]),_c('td',[(item.blueGreen)?_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-info"},[_vm._v("In")]),_c('span',{staticClass:"tag is-success"},[_vm._v(" Progress ")])]):_vm._e()]),_c('td',[_c('span',{staticClass:"tag",class:{
                'is-black': item.status == 'unknown',
                'is-danger': item.status == 'stopped',
                'is-success': item.status == 'running'
              }},[_vm._v(" "+_vm._s(item.status)+" ")])])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Fullpath")]),_c('th',[_vm._v("Expiration date")]),_c('th',[_vm._v("BlueGreen")]),_c('th',[_vm._v("Cluster status")])])])}]

export { render, staticRenderFns }