const USER_INFO_STORE_KEY = "USER_INFO_STORE_KEY";

var strState = localStorage.getItem(USER_INFO_STORE_KEY);
var state = strState
  ? JSON.parse(strState)
  : {
      cognitoInfo: {},
      loggedIn: false,
      loadingState: true,
      errorLoadingState: false,
    };

function setLoggedIn(newValue) {
  state.loggedIn = newValue;
  saveState();
}

function setLoggedOut() {
  state.loggedIn = false;
  saveState();
}

function setCognitoInfo(newValue) {
  state.cognitoInfo = newValue;
  saveState();
}

function saveState() {
  var str = JSON.stringify(state);
  console.log("state: " + str);
  localStorage.setItem(USER_INFO_STORE_KEY, str);
}

export default {
  setLoggedIn: setLoggedIn,
  setLoggedOut: setLoggedOut,
  setCognitoInfo: setCognitoInfo,
  state: state,
};
