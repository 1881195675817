<template>
<div class ="row">
  <!-- Error panel -->
  <div class="notification is-danger is-light" v-if="apiError">
    There was an error retrieving tenant '{{this.$route.params["env"] }} / {{ this.$route.params["tenant"]}}' information. Please contact Platform team.
  </div>

  <div class="row" v-if="tenantInfo">
    <h1 class="title">
      {{ this.$route.params["env"] }} / {{ this.$route.params["tenant"] }}
    </h1>

    <div class="field is-grouped is-grouped-multiline">
      <div
        class="control"
        v-for="key in ['requested_by', 'created_by', 'managed_by']"
        :key="key"
      >
        <div class="tags has-addons">
          <span class="tag is-dark">{{ key }}</span>
          <span
            class="tag is-info"
            v-bind:class="{ 'is-danger': !tenantInfo.header[key] }"
          >
            {{ (tenantInfo.header[key] || "n/d").toLowerCase() }}
          </span>
        </div>
      </div>
    </div>

    <div class="field is-grouped is-grouped-multiline">
      <div
        class="control"
        v-for="key in [
          'active_services',
          'namespace',
          'db_versions',
          'update_date',
        ]"
        :key="key"
      >
        <div class="tags has-addons">
          <span class="tag is-dark">{{ key }}</span>
          <span
            class="tag is-info"
            v-bind:class="{ 'is-danger': !tenantInfo.header[key] }"
          >
            {{ tenantInfo.header[key] || "n/d" }}
          </span>
        </div>
      </div>
      <div class="control">
        <div class="tags has-addons">
          <span class="tag is-dark">expiration_date</span>
          <span
            class="tag is-success"
            v-bind:class="{
              'is-danger':
                tenantInfo.header.expiration_date &&
                tenantInfo.header.expiration_date < new Date(),
            }"
          >
            {{ formatDate(tenantInfo.header.expiration_date) || "n/d" }}
          </span>
        </div>
      </div>
    </div>

<!-- Green version tag -->

    <div class="field is-grouped is-grouped-multiline" v-if="tenantInfo.header.qa_services">
      <div class="control">
        <div class="tags has-addons">
          <span class="tag is-dark">qa_services</span>
          <span            class="tag is-success"          >
            {{tenantInfo.header.qa_services}}
          </span>
        </div>
      </div>
    </div>

<!-- Services table -->
    <div class="row">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Service</th>
            <th>Version</th>
            <th>Revision</th>
            <th>#Replicas</th>
            <th>Repository</th>
            <th>Tag</th>
          </tr>
        </thead>
        <tbody
          v-for="service in Object.keys(tenantInfo.services)"
          :key="service"
        >
          <tr>
            <th colspan="6">{{ service }}</th>
          </tr>
          <tr
            v-for="version in tenantInfo.services[service]"
            :key="version.name"
          >
            <td>
              {{ version.name }}
              <span class="tag is-success" v-if="isQA(version.name)"
                >qa</span
              >
              <span class="tag is-danger" v-if="isUnknown(version.name)"
                >unknown</span
              >
            </td>
            <td>{{ version.version }}</td>
            <td>{{ version.revision }}</td>
            <td>{{ tenantStatus[version.name].spec_replicas }} / {{ version.values.replicaCount }}</td>
            <td>{{ version.values.image.repository }}</td>
            <td>{{ version.values.image.tag }}</td>
          </tr>
          <tr v-if="tenantInfo.services[service].length == 2">
            <td colspan="6">
              <CollapsiblePanel title="Diff" class="full-width">
                <code-diff
                  :old-string="
                    JSON.stringify(
                      tenantInfo.services[service][0].values,
                      null,
                      '\t'
                    )
                  "
                  :new-string="
                    JSON.stringify(
                      tenantInfo.services[service][1].values,
                      null,
                      '\t'
                    )
                  "
                  :context="2"
                />
              </CollapsiblePanel>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</template>
<script>
import VersionsApi from "../app/get-versions-api";
import Utils from "../app/utils";
import CollapsiblePanel from "@/components/CollapsiblePanel";
import CodeDiff from "vue-code-diff";
import axios from "axios";

export default {
  name: "Tenant",
  data: function() {
    return {
      tenantInfo: null,
      tenantStatus: null,
      apiError: false
    };
  },
  methods: {
    isActive: function(name) {
      return (
        !this.tenantInfo.header.qa_services ||
        this.tenantInfo.header.qa_services ==
          this.tenantInfo.header.active_services ||
        (this.tenantInfo.header.active_services &&
          name.endsWith("-" + this.tenantInfo.header.active_services))
      );
    },
    isQA: function(name) {
      return (
        this.tenantInfo.header.qa_services &&
        this.tenantInfo.header.qa_services !=
          this.tenantInfo.header.active_services &&
        this.tenantInfo.header.qa_services &&
        name.endsWith("-" + this.tenantInfo.header.qa_services)
      );
    },
    isUnknown: function(name) {
      return !(this.isActive(name) || this.isQA(name));
    },
    diff: Utils.diff,
    formatDate: Utils.formatDate,
  },
  mounted() {
    const reqDetails=VersionsApi.getTenantDetails(this.$route.params["env"],this.$route.params["tenant"]);
    const reqStatus=VersionsApi.getTenantStatus(this.$route.params["env"],this.$route.params["tenant"]);
    var self=this;
    axios
      .all([reqDetails,reqStatus])
      .then(
        axios.spread((...responses) => {
              this.tenantStatus = responses[1].data.deployments;
              const d = responses[0].data;
              // use/access the results
              console.log(responses);
              var s = d.services;
              var out = { header: d.header, services: {} };
              out.header.update_date = Utils.formatDate(out.header.update_date);
              out.header.expiration_date = out.header.expiration_date
                ? new Date(out.header.expiration_date)
                : null;
              s.forEach((el) => {
                el.values = JSON.parse(el.values);
                out.services[el["chart"]] = out.services[el["chart"]] || [];
                out.services[el["chart"]].push(el);
              });
              this.tenantInfo = out;
            })
      ).catch(function() {
        self.apiError = true;
        console.error("fail");
      }).finally(function(){
        console.log("finally");
      });
  },
  components: {
    CollapsiblePanel,
    CodeDiff,
  },
};
</script>
<style></style>
