<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item">
          <img src="@/assets/ASG-logo-rgb.png" height="28" />
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link to="/" class="navbar-item">
            SRE Environments
          </router-link>
        </div>

        <div class="navbar-end" v-if="!userInfo.loggedIn">
          <div class="navbar-item">
            <div class="buttons">
              <router-link to="/login" class="button is-primary">
                <strong>Login</strong>
              </router-link>
            </div>
          </div>
        </div>

        <div class="navbar-end" v-if="userInfo.loggedIn">
          <div class="navbar-item">
            <!--{{ userInfo.cognitoInfo.username }} -->
            {{ userInfo.cognitoInfo.email }}
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <router-link to="/logout" class="button is-light">
                Logout
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <Breadcrumb />
  </div>
</template>

<script>
import UserInfoStore from "../app/user-info-store";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  data: function () {
    return {
      userInfo: UserInfoStore.state,
    };
  },
  methods: {},
  components: {
    Breadcrumb,
  },
};
</script>
