<template>
  <div>
    <h1 class="title">Environments in {{ versionsInfo.region }}</h1>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>Environment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in versionsInfo.environments"
            v-bind:key="index"
          >
            <td>
              <router-link
                :to="{ name: 'Environment', params: { env: item.name } }"
                >{{ item.name }}</router-link
              >
            </td>
            <td>
              <span
                class="tag is-success"
                v-bind:class="{
                  'is-danger': !item.status,
                  'is-light': item.status,
                }"
              >
                {{ item.status || "stopped" }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import VersionsApi from "../app/get-versions-api";

export default {
  name: "Home",
  data: function() {
    return {
      versionsInfo: { region: null, environments: [] },
    };
  },
  mounted() {
    VersionsApi.getEnvironments().then(
      (response) => (this.versionsInfo = response.data)
    );
  },
};
</script>
<style></style>
