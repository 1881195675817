var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.apiError)?_c('div',{staticClass:"notification is-danger is-light"},[_vm._v(" There was an error retrieving tenant '"+_vm._s(this.$route.params["env"])+" / "+_vm._s(this.$route.params["tenant"])+"' information. Please contact Platform team. ")]):_vm._e(),(_vm.tenantInfo)?_c('div',{staticClass:"row"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(this.$route.params["env"])+" / "+_vm._s(this.$route.params["tenant"])+" ")]),_c('div',{staticClass:"field is-grouped is-grouped-multiline"},_vm._l((['requested_by', 'created_by', 'managed_by']),function(key){return _c('div',{key:key,staticClass:"control"},[_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-dark"},[_vm._v(_vm._s(key))]),_c('span',{staticClass:"tag is-info",class:{ 'is-danger': !_vm.tenantInfo.header[key] }},[_vm._v(" "+_vm._s((_vm.tenantInfo.header[key] || "n/d").toLowerCase())+" ")])])])}),0),_c('div',{staticClass:"field is-grouped is-grouped-multiline"},[_vm._l(([
          'active_services',
          'namespace',
          'db_versions',
          'update_date' ]),function(key){return _c('div',{key:key,staticClass:"control"},[_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-dark"},[_vm._v(_vm._s(key))]),_c('span',{staticClass:"tag is-info",class:{ 'is-danger': !_vm.tenantInfo.header[key] }},[_vm._v(" "+_vm._s(_vm.tenantInfo.header[key] || "n/d")+" ")])])])}),_c('div',{staticClass:"control"},[_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-dark"},[_vm._v("expiration_date")]),_c('span',{staticClass:"tag is-success",class:{
              'is-danger':
                _vm.tenantInfo.header.expiration_date &&
                _vm.tenantInfo.header.expiration_date < new Date(),
            }},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.tenantInfo.header.expiration_date) || "n/d")+" ")])])])],2),(_vm.tenantInfo.header.qa_services)?_c('div',{staticClass:"field is-grouped is-grouped-multiline"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-dark"},[_vm._v("qa_services")]),_c('span',{staticClass:"tag is-success"},[_vm._v(" "+_vm._s(_vm.tenantInfo.header.qa_services)+" ")])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),_vm._l((Object.keys(_vm.tenantInfo.services)),function(service){return _c('tbody',{key:service},[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v(_vm._s(service))])]),_vm._l((_vm.tenantInfo.services[service]),function(version){return _c('tr',{key:version.name},[_c('td',[_vm._v(" "+_vm._s(version.name)+" "),(_vm.isQA(version.name))?_c('span',{staticClass:"tag is-success"},[_vm._v("qa")]):_vm._e(),(_vm.isUnknown(version.name))?_c('span',{staticClass:"tag is-danger"},[_vm._v("unknown")]):_vm._e()]),_c('td',[_vm._v(_vm._s(version.version))]),_c('td',[_vm._v(_vm._s(version.revision))]),_c('td',[_vm._v(_vm._s(_vm.tenantStatus[version.name].spec_replicas)+" / "+_vm._s(version.values.replicaCount))]),_c('td',[_vm._v(_vm._s(version.values.image.repository))]),_c('td',[_vm._v(_vm._s(version.values.image.tag))])])}),(_vm.tenantInfo.services[service].length == 2)?_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('CollapsiblePanel',{staticClass:"full-width",attrs:{"title":"Diff"}},[_c('code-diff',{attrs:{"old-string":JSON.stringify(
                      _vm.tenantInfo.services[service][0].values,
                      null,
                      '\t'
                    ),"new-string":JSON.stringify(
                      _vm.tenantInfo.services[service][1].values,
                      null,
                      '\t'
                    ),"context":2}})],1)],1)]):_vm._e()],2)})],2)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Service")]),_c('th',[_vm._v("Version")]),_c('th',[_vm._v("Revision")]),_c('th',[_vm._v("#Replicas")]),_c('th',[_vm._v("Repository")]),_c('th',[_vm._v("Tag")])])])}]

export { render, staticRenderFns }