import axios from "axios";
import auth from "./auth";

//const GET_VERSIONS_URL = process.env.VUE_APP_GET_VERSIONS_BASE_URL;
//const GET_VERSIONS_URL = window.$config('VUE_APP_GET_VERSIONS_BASE_URL');

export default {
  getVersionsUrl(){
    return window.$config('VUE_APP_GET_VERSIONS_BASE_URL');
  },
  getEnvStatusUrl(){
    return window.$config('VUE_APP_ENV_STATUS_BASE_URL');
  },
  getEnvStatusPrefix(){
    return window.$config('VUE_APP_ENV_STATUS_PREFIX');
  },
  getEnvironments() {
    var jwtToken = auth.auth().getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = this.getVersionsUrl();
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    return axios.get(USERINFO_URL, requestData);
  },
  getEnvironmentDetails(env) {
    var jwtToken = auth.auth().getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = this.getVersionsUrl() + "?env=" + env;
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    return axios.get(USERINFO_URL, requestData);
  },
  getEnvironmentStatus(env) {
    var jwtToken = auth.auth().getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = this.getEnvStatusUrl() + "/" + this.getEnvStatusPrefix() + env;
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    return axios.get(USERINFO_URL, requestData);
  },
  getTenantDetails(env, tenant) {
    var jwtToken = auth.auth().getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = this.getVersionsUrl() + "?env=" + env + "&tenant=" + tenant;
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    return axios.get(USERINFO_URL, requestData);
  },
  getTenantStatus(env, tenant) {
    var jwtToken = auth.auth().getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = this.getEnvStatusUrl() + "/" + this.getEnvStatusPrefix() + env + "/" + tenant;
    var requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    return axios.get(USERINFO_URL, requestData);
  }
};
