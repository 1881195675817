<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li v-for="(item, index) in crumbs" v-bind:key="index">
        <a href="#" v-if="!item.to" aria-current="page">{{ item.title }}</a>
        <router-link
          v-if="item.to"
          :to="{
            name: item.to.name,
            params: item.to.params,
          }"
          >{{ item.title }}</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  //data() {},
  mounted() {},
  watch: {},
  methods: {},
  computed: {
    crumbs: function () {
      var r = this.$route;
      if (!r.meta || !r.meta.breadcrumb) return [];
      return r.meta.breadcrumb.map(function (el) {
        return {
          title: typeof el.title == "function" ? el.title.call(r) : el.title,
          to: el.to
            ? {
                name:
                  typeof el.to.name == "function"
                    ? el.to.name.call(r)
                    : el.to.name,
                params: Object.fromEntries(
                  Object.entries(el.to.params || {}).map(([k, v]) => [
                    k,
                    typeof v == "function" ? v.call(r) : v,
                  ])
                ),
              }
            : null,
        };
      });
    },
  },
};
</script>
