<template>
  <div class="row">
    <div class="col">
      <h2>Logged Out successfully</h2>
    </div>
  </div>
</template>
<script>
import UserInfoStore from "../app/user-info-store";

export default {
  mounted: function() {
    UserInfoStore.setLoggedOut();
  },
};
</script>
