/* eslint-disable */
import { CognitoAuth, StorageHelper } from "amazon-cognito-auth-js";
import IndexRouter from "../router/index";
import UserInfoStore from "./user-info-store";
import UserInfoApi from "./user-info-api";

function getAuth() {
  var authData = {
    ClientId: window.$config('VUE_APP_COGNITO_CLIENT_ID'), // Your client id here
    AppWebDomain: window.$config('VUE_APP_COGNITO_APP_DOMAIN'),
    TokenScopesArray: ["openid", "email", window.$config('VUE_APP_COGNITO_RESOURCE_SERVER_IDENTIFIER') + "/get.versions",  window.$config('VUE_APP_COGNITO_RESOURCE_SERVER_IDENTIFIER') + "/manage.tenants"],
    RedirectUriSignIn: window.$config('VUE_APP_COGNITO_REDIRECT_URI'),
    RedirectUriSignOut: window.$config('VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT'),
    UserPoolId: window.$config('VUE_APP_COGNITO_USERPOOL_ID'),
  };

  var auth = new CognitoAuth(authData);
  auth.userhandler = {
    onSuccess: function (result) {
      console.log("On Success result", result);
      UserInfoStore.setLoggedIn(true);
      UserInfoApi.getUserInfo().then((response) => {
        IndexRouter.push("/");
      });
    },
    onFailure: function (err) {
      UserInfoStore.setLoggedOut();
      IndexRouter.go({
        path: "/error",
        query: { message: "Login failed due to " + err },
      });
    },
  };
  return auth;
}

var _auth = null;

function getUserInfoStorageKey() {
  var keyPrefix = "CognitoIdentityServiceProvider." + getAuth().getClientId();
  var tokenUserName = getAuth().signInUserSession.getAccessToken().getUsername();
  var userInfoKey = keyPrefix + "." + tokenUserName + ".userInfo";
  return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default {
  auth() {
    if (!_auth) {
      _auth = getAuth();
    }
    return _auth;
  },
  login() {
    this.auth().getSession();
  },
  logout() {
    if (this.auth().isUserSignedIn()) {
      var userInfoKey = getUserInfoStorageKey();
      this.auth().signOut();

      storage.removeItem(userInfoKey);
    }
  },
  //getUserInfoStorageKey,
};
