<template>
  <div id="app">
    <Navigation/>
    <div class="contents">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'app',
  components: {
    Navigation
  }
}
</script>

<style>

</style>